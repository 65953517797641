import React from 'react';
import './css/style.css';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//import frontImage from './image/front.jpg';
//import frontRightImage from './image/front_right.jpg';
//import rightSideImage from './image/righatside.jpg';  // タイポに注意

//import frontImage from './image/front.jpg';
//import frontRightImage from './image/front_right.jpg';
//import rightSideImage from './image/righatside.jpg';  // 'rightside.jpg'ではなく'righatside.jpg'を使用


//import frontImage from './front.jpg';
//import frontRightImage from './front_right.jpg';
//import rightSideImage from './righatside.jpg';

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

const NavItem = ({ href, children }) => (
  <li>
    <a 
      href={href} 
      onClick={(e) => {
        e.preventDefault();
        scrollToSection(href.slice(1));
      }}
    >
      {children}
    </a>
  </li>
);

const Header = () => {
  const navItems = ['ホーム', 'サービス', 'スタッフ', 'お問い合わせ'];
  return (
    <header>
      <div className="container">
        <nav>
          <ul>
            {navItems.map((item) => (
              <NavItem key={item} href={`#${item}`}>{item}</NavItem>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

const Hero = () => (
    <section id="home" className="hero">
      <div className="container">
        <h1>長坂・天野
	合同事務所</h1>
	<p>司法書士・土地家屋調査士・行政書士として、皆様の暮らしと事業を法的にサポートいたします</p>
      </div>
    </section>
  );



  const ImageCarousel = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };
  
    return (
      <section className="image-carousel">
        <Slider {...settings}>
	<div>
          <img src="/images/front.jpg" alt="会社外観" />
        </div>
        <div>
          <img src="/images/front_right.jpg" alt="会社外観" />
        </div>
        <div>
          <img src="/images/righatside.jpg" alt="会社外観" />
        </div>
        </Slider>
      </section>
    );
  };


  
  const ServiceList = () => {
    const serviceData = [
      {
        icon: 'fa-home',
        title: '司法書士業務',
        description: [
          {
            subtitle: '不動産登記（権利）',
            items: [
              '相続、生前贈与、売買などによる所有権移転登記',
              '抵当権等の担保権設定や抹消の登記'
            ]
          },
          {
            subtitle: '商業登記',
            items: [
              '役員変更登記、会社設立登記、増資などの変更登記'
            ]
          },
          {
            subtitle: '裁判事務',
            items: [
              '相続放棄、遺言の検認など'
            ]
          },
          {
            subtitle: 'その他',
            items: [
              '遺言作成等のサポート（自筆証書遺言、公正証書遺言）',
              '相続登記に伴う遺産分割協議書作成',
              '法定相続情報作成'
            ]
          }
        ]
      },
      {
        icon: 'fa-building',
        title: '土地家屋調査士業務',
        description: [
          {
            subtitle: '不動産登記（表示）',
            items: [
              '分筆登記、地積更正登記、建物表題登記等'
            ]
          },
          {
            subtitle: '測量業務',
            items: []
          }
        ]
      },
      {
        icon: 'fa-file-alt',
        title: '行政書士業務',
        description: [
          {
            subtitle: '遺産分割協議書作成',
            items: []
          },
          {
            subtitle: '農地転用などの許可申請及び届出',
            items: []
          }
        ]
      }
    ];
  
    return (
      <section id="サービス" className="services">
        {serviceData.map((serviceItem, index) => (
          <ServiceCard key={index} {...serviceItem} />
        ))}
      </section>
    );
  };
  
  const ServiceCard = ({ icon, title, description }) => (
    <div className="service">
      <i className={`fas ${icon}`}></i>
      <h2>{title}</h2>
      <div className="service-description">
        {description.map((section, index) => (
          <div key={index} className="description-section">
            <h3>{section.subtitle}</h3>
            {section.items.length > 0 && (
              <ul>
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
  
  const StaffMember = ({ name, role }) => (
    <div className="staff-member">
      <i className="fas "></i>
      <h3>{name}</h3>
      <p>{role}</p>
    </div>
  );
  
  const Staff = () => {
    const qualifiedStaff = [
      { name: '天野 晃浩', qualifications: '司法書士、土地家屋調査士' },
      { name: '長坂 宏子', qualifications: '土地家屋調査士、行政書士' },
      { name: '白井 直樹', qualifications: '司法書士' }
    ];
  
    return (
      <section id="スタッフ" className="staff">
        <h2>スタッフ紹介</h2>
        <p>スタッフ数: 10名 (内有資格者3名)</p>
        <div className="qualified-staff">
          <p>資格者：</p>
          <ul>
            {qualifiedStaff.map((staff, index) => (
              <li key={index}>
                {staff.name}（{staff.qualifications}）
                {index < qualifiedStaff.length - 1}
              </li>
            ))}
          </ul>
        </div>
      </section>
    );
  };
  const ContactItem = ({ icon, children, type }) => {
    if (type === 'phone') {
      const phoneNumber = children.split(' ').pop(); // 電話番号のみを抽出
      return (
        <p className="contact-item">
          <i className={`fas ${icon}`}></i>
          <span>
            電話番号 <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </span>
        </p>
      );
    }
    
    return (
      <p className="contact-item">
        <i className={`fas ${icon}`}></i>
        <span dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br>') }} />
      </p>
    );
  };
  
  const Contact = () => {
    const contactInfo = [
      { icon: 'fa-map-marker-alt', text: '〒444-0853 愛知県岡崎市三崎町９−５', type: 'address' },
      { icon: 'fa-phone', text: '電話番号 0564-52-8530', type: 'phone' },
      { icon: 'fa-fax', text: 'FAX 0564-51-7996', type: 'fax' },
      { icon: 'fa-calendar-times', text: '営業時間\n平日：8時45分～17時45分\n第１第３第5土曜：8時45分～13時\n\n定休日\n日曜日、祝日、第2第４土曜日', type: 'hours' }
    ];
    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=長坂天野合同事務所,岡崎市三崎町９−５`;
    
    return (
      <section id="お問い合わせ" className="contact-map">
        <div className="contact-info">
          <h2>お問い合わせ</h2>
          {contactInfo.map((item, index) => (
            <ContactItem key={index} icon={item.icon} type={item.type}>{item.text}</ContactItem>
          ))}
        </div>
        <div className="map">
          <iframe
            width="100%"
            height="100%"
            style={{border:0}}
            loading="lazy"
            allowFullScreen
            src={mapUrl}
            title="長坂・天野合同事務所の場所"
          ></iframe>
        </div>
      </section>
    );
  };

  const Footer = () => (
    <footer>
      <div className="container">
        <p>&copy; 2024 長坂天野合同事務所. All rights reserved.</p>
      </div>
    </footer>
  );
  
  

export const App = () => {

    return (
        <>
            <Header />
            <main className="container">
              <Hero />
              <ImageCarousel />
              <ServiceList />
              <Staff />
              <Contact />
            </main>
            <Footer />
        </>
    )
  };
  
